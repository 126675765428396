export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    deleteToken(state) {
      state.token = null;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    deleteUser(state) {
      state.user = null;
    },
  },
  actions: {
    setUserData({ commit }, payload) {
      commit("setUser", payload.user);
      commit("setToken", payload.token);
    },
    logoutUser({ commit }) {
      commit("deleteUser");
      commit("deleteToken");
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    userRole(state) {
      return state.user ? state.user.role : null;
    },
    userPaymentModel(state) {
      return state.user ? state.user["payment_model"] : null;
    },
  },
};
